
import { Component, Vue } from 'vue-property-decorator'

import AppLayout from '@/components/layout/AppLayout.vue'
import Cars from '@/components/content/pages/Cars.vue'
import Policies from '@/components/content/pages/Policies.vue'
import ErrorNotifier from './components/entities/ErrorNotifier'

import { TDefaultTabs } from './types/default'

@Component({
  components: {
    AppLayout,
    Cars,
    Policies,
    ErrorNotifier
  }
})
export default class App extends Vue {
  page: TDefaultTabs = 'cars'

  isPage (page: string) {
    return this.page === page
  }

  onCurrentUpdate (value: TDefaultTabs) {
    this.page = value
  }
}
