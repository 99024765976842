import { render, staticRenderFns } from "./Policies.vue?vue&type=template&id=33cc11a8&"
import script from "./Policies.vue?vue&type=script&lang=ts&"
export * from "./Policies.vue?vue&type=script&lang=ts&"
import style0 from "./Policies.vue?vue&type=style&index=0&id=33cc11a8&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports