
import { Vue, Component, Prop, Emit } from 'vue-property-decorator'

type TButtonColor = '' | 'light' | 'dark' | 'red' | 'transparent' | 'transparent-dark'
type TButtonSize = 'large' | 'medium' | 'small'
type TButtonTag = 'button' | 'a'

@Component
export default class Button extends Vue {
  @Prop({ type: String, default: '' })
  readonly color: TButtonColor

  @Prop({ type: String, default: 'medium' })
  readonly size: TButtonSize

  @Prop({ type: String, default: 'button' })
  readonly tag: TButtonTag

  @Prop({ type: Boolean, default: false })
  readonly disabled: boolean

  @Prop({ type: Boolean, default: false })
  readonly outline: boolean

  @Prop({ type: Boolean, default: false })
  readonly wider: boolean

  @Prop({ type: Boolean, default: false })
  readonly block: boolean

  @Prop({ type: Boolean, default: false })
  readonly prependIcon: boolean

  @Prop({ type: Boolean, default: false })
  readonly apendIcon: boolean

  @Prop({ type: Boolean, default: false })
  readonly icon: boolean

  @Prop({ type: Boolean, default: false })
  readonly tone: boolean

  get classes () {
    return {
      [`c-button_${this.color}`]: this.color,
      [`c-button_${this.size}`]: this.size,
      'c-button_outline': this.outline,
      'c-button_wider': this.wider,
      'c-button_block': this.block,
      'c-button_prepend-icon': this.prependIcon,
      'c-button_apend-icon': this.apendIcon,
      'c-button_icon': this.icon,
      'c-button_tone': this.tone
    }
  }

  @Emit('click')
  onClick () {}
}
