
import { Vue, Component, Prop, Watch, Emit } from 'vue-property-decorator'
import { parse, format } from 'date-fns'
import getUnixTime from 'date-fns/getUnixTime'
import _ from 'lodash'

import { THead, TRenderableTableDate, TSelectIndicator, TSort } from '@/types/data_table'
import { TSpecialCar } from '@/types/api'

import Checkbox from '@/components/UI/Checkbox.vue'
import Button from '@/components/UI/Button.vue'
import TableSortActivator from '@/components/UI/TableSortActivator.vue'
import DataTableControls from '@/components/UI/DataTableControls.vue'
import DataSelectIndicator from '@/components/UI/DataSelectIndicator.vue'
import EditableField from '@/components/UI/EditableField.vue'

@Component({
  components: {
    Checkbox,
    Button,
    TableSortActivator,
    DataTableControls,
    DataSelectIndicator,
    EditableField
  }
})
export default class DataTable extends Vue {
  @Prop(Array)
  readonly head: string[]

  @Prop(Array)
  readonly data: TSpecialCar[]

  @Prop(String)
  readonly search: string

  @Prop(Boolean)
  readonly actions: boolean

  tableHead: THead[] = []
  tableBody: TRenderableTableDate[] = []

  mainSelectState: TSelectIndicator = ''

  edittedComment: string = ''

  get selectedLength () {
    return this.tableBody.filter(_r => _r.selected).length
  }

  get allRowsSelected () {
    return this.selectedLength === this.tableBody.length
  }

  get sortBy () {
    return this.tableHead.find(_h => _h.sort)
  }

  get sortedRows () {
    if (!this.sortBy) {
      return this.tableBody
    }

    const { sort, title } = this.sortBy
    const _table = _.cloneDeep(this.tableBody)

    if (title === 'VIN') {
      _table.sort((a, b) => sort === 'ascending' ? ('' + a.vin).localeCompare(b.vin) : ('' + b.vin).localeCompare(a.vin))
    }

    if (title === 'Карта') {
      _table.sort((a, b) => {
        if (a.kasko?.cardId && b.kasko?.cardId) {
          return sort === 'ascending' ? a.kasko.cardId - b.kasko.cardId : b.kasko.cardId - a.kasko.cardId
        } else if (a.kasko?.cardId) {
          return sort === 'ascending' ? 1 : -1
        } else if (b.kasko?.cardId) {
          return sort === 'ascending' ? -1 : 1
        }

        return 0
      })
    }

    if (title === 'Собственник') {
      _table.sort((a, b) => sort === 'ascending' ? ('' + a.ownerName).localeCompare(b.ownerName) : ('' + b.ownerName).localeCompare(a.ownerName))
    }

    if (title === 'Полис до') {
      _table.sort((a, b) => {
        if (a.kasko.disabledAt && b.kasko.disabledAt) {
          const parsedA = parse(a.kasko.disabledAt, 'yyyy-MM-dd', new Date())
          const parsedB = parse(b.kasko.disabledAt, 'yyyy-MM-dd', new Date())

          return sort === 'ascending' ? getUnixTime(parsedA) - getUnixTime(parsedB) : getUnixTime(parsedB) - getUnixTime(parsedA)
        } else if (a.kasko.disabledAt) {
          return sort === 'ascending' ? 1 : -1
        } else if (b.kasko.disabledAt) {
          return sort === 'ascending' ? -1 : 1
        }

        return 0
      })
    }

    if (title === 'КАСКО') {
      _table.sort((a, b) => {
        const aStatus = a.kasko?.status === 'active'
        const bStatus = b.kasko?.status === 'active'

        return sort === 'ascending' ? Number(aStatus) - Number(bStatus) : Number(bStatus) - Number(aStatus)
      })
    }

    return _table
  }

  mounted () {
    this.createHeadings()
  }

  @Watch('data')
  onDataChanged () {
    this.createData()
  }

  @Emit('update-data')
  onUpdateData () {}

  createHeadings () {
    this.tableHead = this.head.map(item => ({ title: item, sort: '' }))
  }

  createData () {
    const data = this.data.map(item => ({
      ...item,
      selected: false
    }))
    this.tableBody = data
  }

  onSelectChange (index: number) {
    this.tableBody[index].selected = !this.tableBody[index].selected

    this.updateMainSelectState()
  }

  onSelectAllChange (state: boolean) {
    this.tableBody = this.tableBody.map(_row => ({
      ..._row,
      selected: state
    }))
    this.mainSelectState = state ? 'all' : ''
  }

  async onKaskoChange (state: boolean) {
    const rows = this.tableBody.filter(_r => _r.selected)
    const requests: Promise<any>[] = []

    if (rows.length) {
      if (state) {
        rows.forEach((_row, _index) => {
          if (_row.kasko.status === 'inactive') {
            requests.push(
              this.$api.activateKaskoSpecialCars(_row.id)
            )

            this.tableBody[_index].kasko.status = 'active'
          }
        })
      } else {
        rows.forEach((_row, _index) => {
          if (_row.kasko.status === 'active') {
            requests.push(
              this.$api.deactivateKaskoSpecialCars(_row.id)
            )

            this.tableBody[_index].kasko.status = 'inactive'
          }
        })
      }

      try {
        await Promise.all(requests)
      } catch (error: unknown) {
        if (this.$api.isSafeError(error)) {
          this.$error.push(error)
        }

        this.onUpdateData()
      }
    }
  }

  onRemove () {
    const selected = this.tableBody.filter(_r => _r.selected)

    if (selected.length) {
      this.$mitt.emit('remove-cars', selected)
    }
  }

  onMainSelectClick (value: TSelectIndicator) {
    this.mainSelectState = value

    if (value === 'all') {
      this.onSelectAllChange(true)
    } else {
      this.onSelectAllChange(false)
    }
  }

  onRowSelect (state: boolean, index: number) {
    this.tableBody[index].selected = state

    this.updateMainSelectState()
  }

  onSortChange (value: TSort, headItem: THead) {
    this.tableHead = this.tableHead.map(_h => ({
      ..._h,
      sort: ''
    }))

    const _index = this.tableHead.findIndex(_h => _h.title === headItem.title)

    if (_index >= 0) {
      this.tableHead[_index].sort = value
    }
  }

  async onKaskoStateClick (index: number) {
    const car = this.tableBody[index]
    const state = car.kasko.status === 'active'

    if (!state) {
      try {
        await this.$api.activateKaskoSpecialCars(car.id)

        this.tableBody[index].kasko.status = 'active'
      } catch (error: unknown) {
        if (this.$api.isSafeError(error)) {
          this.$error.push(error)
        }
      }
    } else {
      try {
        await this.$api.deactivateKaskoSpecialCars(car.id)

        this.tableBody[index].kasko.status = 'inactive'
      } catch (error: unknown) {
        if (this.$api.isSafeError(error)) {
          this.$error.push(error)
        }
      }
    }
  }

  updateMainSelectState () {
    if (this.allRowsSelected) {
      this.mainSelectState = 'all'
    } else if (this.selectedLength) {
      this.mainSelectState = 'partial'
    } else {
      this.mainSelectState = ''
    }
  }

  getDate (date: Nullable<string>) {
    if (date) {
      return format(parse(date.split(' ')[0], 'yyyy-MM-dd', new Date()), 'dd.MM.yyyy')
    }

    return ''
  }

  getTime (date: Nullable<string>) {
    if (date) {
      return date.split(' ')[1]
    }

    return ''
  }

  async onCommentUpdate (id: string, comment: string) {
    const car = this.data.find(item => item.id === id)

    if (car) {
      const reserved = String(car.kasko.comment)
      car.kasko.comment = comment

      try {
        await this.$api.updateSpecialCars(id, car)
      } catch (error: unknown) {
        if (this.$api.isSafeError(error)) {
          this.$error.push(error)
        }

        car.kasko.comment = reserved
      }
    } else {
      this.$error.push({
        title: 'Ошибка обновлвения комментария',
        message: 'Машина не найдена'
      })
    }
  }
}
