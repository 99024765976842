
import { Vue, Component, Prop, Emit, Ref } from 'vue-property-decorator'
import Button from '@/components/UI/Button.vue'

@Component({
  components: {
    Button
  }
})
export default class InputFile extends Vue {
  @Prop(String)
  readonly title: string

  @Prop(String)
  readonly accept: string

  @Prop(String)
  readonly acceptDescription: string

  @Prop(String)
  readonly size: string

  @Ref()
  readonly input!: HTMLInputElement

  dragover: boolean = false
  error: boolean = false

  fileName: string = ''

  get classes () {
    return {
      'c-input-file_dragover': this.dragover,
      'c-input-file_error': this.error
    }
  }

  get acceptData () {
    return this.accept.split(', ')
  }

  get buttonText () {
    return this.fileName ? this.fileName : 'Загрузить файл'
  }

  mounted () {
    this.input.addEventListener('dragenter', this.onDragOver)
    this.input.addEventListener('dragleave', this.onDragLeave)
    this.input.addEventListener('drop', this.onDrop)
  }

  beforeDestroy () {
    this.input.removeEventListener('dragenter', this.onDragOver)
    this.input.removeEventListener('dragleave', this.onDragLeave)
    this.input.removeEventListener('drop', this.onDrop)
  }

  @Emit('update')
  onUpdate (file: File) {
    return file
  }

  onDragOver () {
    this.dragover = true
  }

  onDragLeave (e: DragEvent) {
    this.dragover = false
  }

  onDrop (e: DragEvent) {
    e.preventDefault()

    const { dataTransfer } = e

    this.error = false
    this.fileName = ''

    if (dataTransfer) {
      const { files } = dataTransfer

      if (this.acceptData.includes(files[0].type)) {
        const file = files[0]

        this.fileName = file.name
        this.onUpdate(file)
      } else {
        this.error = true
      }
    }
  }

  onChange (e: Event) {
    const files = (e.target as HTMLInputElement).files

    if (files?.length && this.acceptData.includes(files[0].type)) {
      const file = files[0]

      this.fileName = file.name
      this.onUpdate(file)
    } else {
      this.error = true
    }
  }

  onClick () {}
}
