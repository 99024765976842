
import { Vue, Component, VModel, Prop, Emit } from 'vue-property-decorator'
import { declOfNum } from '@/utils/declOfNum'

import { TRenderableTableDate } from '@/types/data_table'

import Modal from '@/components/UI/Modal.vue'
import Button from '@/components/UI/Button.vue'

@Component({
  components: {
    Modal,
    Button
  }
})
export default class RemoveModal extends Vue {
  @VModel({ type: Boolean })
  open: boolean

  @Prop({ type: Array, default: () => [] })
  data: TRenderableTableDate[]

  error: string = ''

  get isMultiple () {
    return this.data.length > 1
  }

  get title () {
    if (this.isMultiple) {
      const decl = declOfNum(this.data.length, ['машина', 'машины', 'машин'])

      return `Удалить ${ this.data.length } ${ decl }?`
    }

    return 'Удалить машину?'
  }

  @Emit('success')
  onSuccess (ids: string[]) {
    return ids
  }

  onCancelClick () {
    this.open = false
  }

  async onRemoveClick () {
    this.error = ''

    const requests = []

    try {
      for (const _d of this.data) {
        requests.push(
          this.$api.deleteSpecialCars(_d.id)
        )
      }

      const responses = await Promise.all(requests)

      this.onSuccess(this.data.map(_d => _d.id))
    } catch (error: unknown) {
      if (this.$api.isSafeError(error)) {
        this.$error.push(error)
      }
    }
  }
}
