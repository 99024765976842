
import { Vue, Prop, Component } from 'vue-property-decorator'

@Component({})
export default class Header extends Vue {
  @Prop({ type: String, default: '' })
  readonly title!: string

  @Prop({ type: String, default: '' })
  readonly subtitle!: string
}
