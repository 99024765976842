
import { Vue, Component, Prop } from 'vue-property-decorator'
import _ from 'lodash'
import parse from 'date-fns/parse'
import {format} from 'date-fns'
import getUnixTime from 'date-fns/getUnixTime'

import {THead} from '@/types/data_table'
import {TPolicy} from '@/types/api'

import Checkbox from '@/components/UI/Checkbox.vue'
import Button from '@/components/UI/Button.vue'
import TableSortActivator from '@/components/UI/TableSortActivator.vue'
import DataTableControls from '@/components/UI/DataTableControls.vue'
import DataSelectIndicator from '@/components/UI/DataSelectIndicator.vue'

type TDateSortRecord = Record<string, TDateSortDictionary>
type TDateSortDictionary = { key: keyof TPolicy, mask: string }

const sortDateFields = ['Выпущен', 'Действует от', 'Действует до']
const sortFieldDictionary: TDateSortRecord = {
  'Выпущен': { key: 'createdAt', mask: 'yyyy-MM-dd HH:mm:ss' },
  'Действует от': { key: 'dateStart', mask: 'yyyy-MM-dd' },
  'Действует до': { key: 'dateEnd', mask: 'yyyy-MM-dd' }
}

@Component({
  components: {
    Checkbox,
    Button,
    TableSortActivator,
    DataTableControls,
    DataSelectIndicator
  }
})
export default class DataTablePolicies extends Vue {
  @Prop(Boolean)
  readonly actions: boolean

  @Prop(Array)
  readonly head: string[]

  @Prop(Array)
  readonly data: TPolicy[]

  @Prop(String)
  readonly search: string

  tableHead: THead[] = []

  get sortBy () {
    return this.tableHead.find(_h => _h.sort)
  }

  get sortedRows () {
    // TODO: Запилить нормальную сортировку без coderepeat-hell'
    // Отрефакторить сортировку по божески
    if (!this.sortBy) {
      return this.data
    }

    const { sort, title } = this.sortBy
    const _table = _.cloneDeep(this.data)

    if (title === 'Карта') {
      _table.sort((a, b) => {
        if (a.cardId && b.cardId) {
          return sort === 'ascending' ? a.cardId - b.cardId : b.cardId - a.cardId
        } else if (a.cardId) {
          return sort === 'ascending' ? 1 : -1
        } else if (b.cardId) {
          return sort === 'ascending' ? -1 : 1
        }

        return 0
      })
    }

    if (title === 'VIN') {
      _table.sort((a, b) => sort === 'ascending' ? ('' + a.vin).localeCompare(b.vin) : ('' + b.vin).localeCompare(a.vin))
    }

    if (title === 'Полис') {
      _table.sort((a, b) => sort === 'ascending' ? ('' + a.number).localeCompare(b.number) : ('' + b.number).localeCompare(a.number))
    }

    if (sortDateFields.includes(title)) {
      const sortField = sortFieldDictionary[title]

      _table.sort((a, b) => {
        if (a[sortField.key] && b[sortField.key]) {
          const parsedA = parse(a[sortField.key] as string, sortField.mask, new Date())
          const parsedB = parse(b[sortField.key] as string, sortField.mask, new Date())

          return sort === 'ascending' ? getUnixTime(parsedA) - getUnixTime(parsedB) : getUnixTime(parsedB) - getUnixTime(parsedA)
        } else if (a[sortField.key]) {
          return sort === 'ascending' ? 1 : -1
        } else if (b[sortField.key]) {
          return sort === 'ascending' ? -1 : 1
        }

        return 0
      })
    }

    if (title === 'Сумма, ₽') {
      _table.sort((a, b) => {
        const sortA = Number(a.price)
        const sortB = Number(b.price)

        return sort === 'ascending' ? sortA - sortB : sortB - sortA
      })
    }

    return _table
  }

  created () {
    this.createHeadings()
  }

  createHeadings () {
    this.tableHead = this.head.map(item => ({ title: item, sort: '' }))
  }

  getDate (date: Nullable<string>) {
    if (date) {
      return date.split(' ')[0]
    }

    return ''
  }

  getTime (date: Nullable<string>) {
    if (date) {
      return date.split(' ')[1]
    }

    return ''
  }

  toLocalTimeZone (dateString: string) {
    const splittedDateString = dateString.split(' ')
    // Москва UTC/GMT +3
    const date = new Date(`${splittedDateString[0]}T${splittedDateString[1]}+03:00`)

    return format(date, 'yyyy-MM-dd HH:mm:ss')
  }

  getCreatedAtHtml (datestring: string) {
    const splitted = datestring.split(' ')
    const date = splitted[0]
    const time = splitted[1]

    return `<div class="c-data-table__main">${date}</div><div class="c-data-table__secondary">${time}</div>`
  }
}
