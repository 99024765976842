
import { Vue, Component, VModel, Prop } from 'vue-property-decorator'

@Component
export default class Checkbox extends Vue {
  @VModel({ type: Boolean }) model: boolean

  @Prop(Boolean)
  readonly switchMode: boolean

  @Prop({ type: String, default: 'label' })
  readonly tag: 'label' | 'div'

  onChange (e: Event) {
    this.model = (e.target as HTMLInputElement).checked
  }
}
