
import { Vue, Component, VModel, Watch, Emit } from 'vue-property-decorator'
import { TImportableJsonSpecialCar } from '@/types/json'

import Modal from '@/components/UI/Modal.vue'
import AddCarImportContent from './AddCarImportContent.vue'
import AddCarImportReadyContent from './AddCarImportReadyContent.vue'
import AddCarContent from './AddCarContent.vue'
import { TAddSpecialCarDTO } from '@/types/api'

type TModalStepIds = 'add-car' | 'import-car' | 'import-ready-car'
type TModalStep = {
  id: TModalStepIds
  title: string
  prev: Nullable<TModalStepIds>
}

const defaultStep = 'add-car'
const modalSteppedData: TModalStep[] = [
  {
    id: 'add-car',
    title: 'Добавить машину',
    prev: null
  },
  {
    id: 'import-car',
    title: 'Импорт машин',
    prev: 'add-car'
  },
  {
    id: 'import-ready-car',
    title: 'Импорт машин',
    prev: 'import-car'
  }
]

@Component({
  components: {
    Modal,
    AddCarImportContent,
    AddCarImportReadyContent,
    AddCarContent
  }
})
export default class AddCarModal extends Vue {
  @VModel({ type: Boolean })
  open: boolean

  step = 'add-car'

  importData: TAddSpecialCarDTO[] = []

  get currentStep () {
    return modalSteppedData.find(_s => _s.id === this.step) as TModalStep
  }

  @Watch('open')
  onOpenChanged (newValue: boolean) {
    if (!newValue) {
      this.beforeClose()
    }
  }

  @Emit('update-data')
  isUpdateData () {}

  isStep (step: string) {
    return this.step === step
  }

  setStep (step: string) {
    this.step = step
  }

  onImportReady (obj: TImportableJsonSpecialCar[]) {
    // Конвертируем данные таблицы в TAddSpecialCarDTO
    this.importData = obj.map(_car => ({
      vin: _car.VIN,
      ownerName: _car['Название предприятия-владельца'],
      ownerInn: String(_car['ИНН предприятия - владельца']),
      maker: _car['Коммерческое наименование'],
      model: _car['Марка, модель ТС'],
      year: String(_car['Год выпуска']),
      weightNetto: String(_car['Масса Нетто ТС, кг']),
      weightMax: String(_car['Масса ТС, кг (В снаряженном состоянии)']),
      ptsNumber: String(_car['ПТС №']),
      ptsDate: String(_car['Дата выдачи ПТС (ДД.ММ.ГГГГ)']),
      kasko: {
        cardId: String(_car['Номер топливной карты']),
        comment: ''
      }
    }))

    this.setStep('import-ready-car')
  }

  onDone () {
    this.isUpdateData()
    this.open = false
  }

  onBack () {
    const currentPrev = this.currentStep.prev

    if (currentPrev) {
      this.setStep(currentPrev)
    }
  }

  beforeClose () {
    this.setStep(defaultStep)
  }
}
