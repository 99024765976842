
import {Vue, Component, VModel, Prop} from 'vue-property-decorator'

@Component
export default class TextArea extends Vue {
  @VModel({type: String}) val: string

  @Prop(String) readonly label: string

  onInput (e: Event) {
    this.val = (e.target as HTMLDivElement).innerText
  }
}
