
import { Vue, Component, Emit } from 'vue-property-decorator'
import Modal from '@/components/UI/Modal.vue'
import Button from '@/components/UI/Button.vue'
import InputFile from '@/components/UI/InputFile.vue'
import AccentBox from '@/components/UI/AccentBox.vue'
import AddCarForm from './AddCarForm.vue'

@Component({
  components: {
    Modal,
    Button,
    InputFile,
    AccentBox,
    AddCarForm
  }
})
export default class AddCarContent extends Vue {
  @Emit('set-step')
  onImportClick () {
    return 'import-car'
  }

  @Emit('create')
  onCreate () {}
}
