
import { Vue, Component, Prop, Emit, Model, VModel } from 'vue-property-decorator'

import Button from './Button.vue'

@Component({
  components: {
    Button
  }
})
export default class Pagination extends Vue {
  @VModel({ type: Number, required: true })
  page!: number

  @Prop({ type: Number, required: true })
  readonly pageSize: number

  @Prop({ type: Number, required: true })
  readonly pageCount: number

  @Prop({ type: Number, default: 5 })
  readonly visiblePagesCount: number

  @Prop({ type: Number, default: 2 })
  readonly ellipsisThreshold: number

  get isMinimumPages () {
    return this.pageCount < this.visiblePagesCount
  }

  get isPrevDisabled () {
    return this.page === 1
  }

  get isNextDisabled () {
    return this.page === this.pageCount
  }

  get visiblePages () {
    if (this.isMinimumPages) {
      return Array.apply(null, Array(this.pageCount)).map((_, i) => i + 1)
    }

    const startPage = Math.max(2, this.page - Math.floor(this.visiblePagesCount / 2))
    const endPage = Math.min(this.pageCount - 1, startPage + this.visiblePagesCount - 1)

    let pages = []

    for (let page = startPage; page <= endPage; page++) {
      pages.push(page)
    }

    return pages
  }

  get showEllipsisAfterFirst () {
    return this.visiblePages[0] - this.ellipsisThreshold >= 1
  }

  get showEllipsisBeforeLast () {
    return this.pageCount - this.visiblePages[this.visiblePages.length - 1] > this.ellipsisThreshold
  }
}
