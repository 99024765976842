
import { Vue, Component, VModel } from 'vue-property-decorator'

@Component
export default class TableSortActivator extends Vue {
  @VModel({ type: String, default: '' }) model!: 'descending' | 'ascending' | ''

  onClick () {
    switch (this.model) {
      case '':
        this.model = 'descending'
        break

      case 'descending':
        this.model = 'ascending'
        break

      case 'ascending':
        this.model = ''
        break
    }
  }
}
