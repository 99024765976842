
import { Vue, Component, Emit, Prop } from 'vue-property-decorator'
import { isAxiosError } from 'axios'
import { TAddSpecialCarDTO } from '@/types/api'

import Button from '@/components/UI/Button.vue'

@Component({
  components: {
    Button
  }
})
export default class AddCarImportReadyContent extends Vue {
  @Prop({
    type: Array
  })
  data: TAddSpecialCarDTO[]

  error: string = ''
  errorDetail: string = ''

  @Emit('create')
  onCreate() {}

  async onSubmit () {
    const requests = []

    this.error = ''
    this.errorDetail = ''

    for (const req of this.data) {
      requests.push(
        this.$api.createSpecialCars(req)
      )
    }

    try {
      await Promise.all(requests)

      this.onCreate()
    } catch (error: unknown) {
      if (this.$api.isSafeError(error)) {
        this.$error.push(error)
      }
    }
  }
}
