
import {Vue, Component, Emit} from 'vue-property-decorator'
import {required, minLength, minValue, numeric, maxLength} from 'vuelidate/lib/validators'
import {parse, format} from 'date-fns'

import {formValidationErrors} from '@/data/form_validation_errors'
import {isValidAndNotFutureDate} from '@/utils/customValidators'

import {TAddSpecialCarDTO, TAddSpecialCarResponseData} from '@/types/api'

import Button from '@/components/UI/Button.vue'
import Input from '@/components/UI/Input.vue'
import InputDate from '@/components/UI/InputDate.vue'
import InputClearButton from '@/components/UI/InputClearButton.vue'

function isMoreThanMaxWeight (value: string) {
  // @ts-ignore
  return Number(value) > Number(this.form.weightNetto)
}

@Component({
  components: {
    Button,
    Input,
    InputDate,
    InputClearButton
  },
  validations: {
    form: {
      vin: { required },
      ownerName: { required },
      ownerInn: { required, minLength: minLength(10), maxLength: maxLength(12) },
      maker: { required },
      model: { required },
      year: { required, numeric, minValue: minValue(1900) },
      weightNetto: { required, numeric },
      // @ts-ignore
      weightMax: { required, numeric, isMoreThanMaxWeight },
      ptsNumber: { required, minLength: minLength(10), maxLength: maxLength(15) },
      ptsDate: { required, isValidAndNotFutureDate },
      kasko: {
        cardId: { required, minLength: minLength(1), numeric },
        comment: { maxLength: maxLength(255) }
      }
    }
  }
})
export default class AddCarForm extends Vue {
  form: TAddSpecialCarDTO = {
    vin: '',
    ownerName: '',
    ownerInn: '',
    maker: '',
    model: '',
    year: '',
    weightNetto: '',
    weightMax: '',
    ptsNumber: '',
    ptsDate: '',
    kasko: {
      cardId: '',
      comment: ''
    }
  }

  error: string = ''

  get errors () {
    return formValidationErrors
  }

  async onSubmit () {
    this.error = ''
    this.$v.$touch()

    if (this.$v.$invalid) {
      return
    }

    try {
      const response = await this.$api.createSpecialCars({
        ...this.form,
        ptsDate: format(parse(this.form.ptsDate, 'dd.MM.yyyy', new Date), 'yyyy-MM-dd')
      })

      this.onCreate(response.data)
    } catch (error: unknown) {
      if (this.$api.isSafeError(error)) {
        this.$error.push(error)
      }
    }
  }

  @Emit('create')
  onCreate (data: TAddSpecialCarResponseData) {
    return data
  }
}
