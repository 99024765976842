
import {Vue, Component, Prop, Emit} from 'vue-property-decorator'

import Modal from '@/components/UI/Modal.vue'
import TextArea from '@/components/UI/TextArea.vue'
import Button from '@/components/UI/Button.vue'

@Component({
  components: {
    Modal,
    TextArea,
    Button
  }
})
export default class EditableField extends Vue {
  @Prop(String) readonly text!: string
  @Prop({type: String, default: 'Примечание'}) readonly placeholder!: string

  @Emit('update')
  resetCount() {
    return this.text
  }

  modal: boolean = false

  edittedText = ''

  get hasText () {
    return !!this.text.trim()
  }

  get label () {
    return this.hasText ? this.text : this.placeholder
  }

  get modalTitle () {
    return this.hasText ? 'Редактировать примечание' : 'Добавить примечание'
  }

  onActionClick () {
    this.edittedText = this.text ?? ''
    this.modal = true
  }

  onCloseActionClick () {
    this.edittedText = ''
    this.modal = false
  }

  @Emit('update')
  onSubmitEmit () {
    return this.edittedText.trim()
  }

  onSubmit () {
    this.onSubmitEmit()
    this.onCloseActionClick()
  }
}
