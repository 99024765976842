
import { Vue, Component, Prop, VModel } from 'vue-property-decorator'
import { TSerializeDefaultData } from '@/types/default'

@Component
export default class Tabs extends Vue {
  @Prop({
    type: Array,
    default: () => []
  })
  options!: TSerializeDefaultData[]

  @VModel({ type: String }) current!: string

  onActivatorClick (value: string) {
    this.current = value
  }
}
