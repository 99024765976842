
import { Vue, Component, Prop, VModel, Emit } from 'vue-property-decorator'

@Component
export default class Modal extends Vue {
  @Prop(String)
  readonly title: string

  @Prop(String)
  readonly subtitle: string

  @Prop({ type: [String, Number], default: '' })
  readonly width: string | number

  @Prop({ type: String, default: 'px' })
  readonly widthDimension: string

  @Prop({ type: Boolean })
  readonly hasBackButton: boolean

  @Prop({ type: Boolean, default: false })
  readonly subscreen: boolean

  @VModel({ type: Boolean })
  open: boolean

  @Emit('back')
  onBack () {}

  get bodyWidthStyle () {
    if (this.width) {
      if (typeof this.width === 'string') {
        const regex = /\d+/g
        const _w = this.width.match(regex)

        if (_w?.length) {
          return {
            width: _w[0] + this.widthDimension
          }
        }

        return {}
      } else {
        return {
          width: this.width + this.widthDimension
        }
      }
    }

    return {}
  }

  get classes () {
    return {
      'c-modal_subscreen': this.subscreen
    }
  }

  close () {
    this.open = false
  }
}
