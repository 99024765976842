
import { Vue, Component, Prop, VModel, Emit } from 'vue-property-decorator'

@Component
export default class Input extends Vue {
  @Prop({ type: String, default: 'text' })
  readonly type: 'text' | 'password'

  @Prop(String)
  readonly placeholder: string

  @Prop(String)
  readonly error: string

  @Prop({ type: String, default: '' })
  readonly maxLength: string

  @Prop({ type: String, default: '' })
  readonly mask: string

  @VModel({ type: String }) val!: string

  @Emit('enter')
  onEnter (e: Event) {
    return (e.target as HTMLInputElement).value
  }

  get classes () {
    return {
      'c-input_error': this.error
    }
  }

  onInput (e: Event) {
    this.val = (e.target as HTMLInputElement).value
  }
}
