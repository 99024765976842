import * as XLSX from 'xlsx'

export async function excelToJson <T>(file: File): Promise<T> {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader()
    fileReader.readAsBinaryString(file)
    fileReader.onload = (e) => {
      const data = e.target?.result

      if (data) {
        const workbook = XLSX.read(data, { type: 'binary' })
        const sheetName = workbook.SheetNames[0]
        const sheet = workbook.Sheets[sheetName]

        // @ts-ignore :(
        const rowObject: T = XLSX.utils.sheet_to_row_object_array(sheet)

        return resolve(rowObject)
      } else {
        return reject('Файл не подходит для импорта, проверьте целостность файла, не нарушена ли структура таблицы')
      }
    }
  })
}
