
import { Vue, Component, VModel, Prop } from 'vue-property-decorator'

@Component
export default class DatePicker extends Vue {
  @Prop({ type: Date, default: () => new Date() })
  readonly minDate: Date

  @VModel({ type: Date })
  date: Date

  locale = {
    id: 'ru',
    masks: { weekdays: 'WW' }
  }
}
