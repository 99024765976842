
import { Vue, Component } from 'vue-property-decorator'

import { TPolicy } from '@/types/api'
import { TRenderablePolicies } from '@/types/data_table'
import { TPaginationData } from '@/types/pagination'

import Input from '@/components/UI/Input.vue'
import Button from '@/components/UI/Button.vue'
import Pagination from '@/components/UI/Pagination.vue'
import DataTablePolicies from '@/components/UI/DataTable.Policies.vue'

@Component({
  components: {
    Input,
    Button,
    Pagination,
    DataTablePolicies
  }
})
export default class Policies extends Vue {
  search: string = ''

  tableHead = [
    'Карта',
    'VIN',
    'Полис',
    'Выпущен',
    'Действует от',
    'Действует до',
    'Сумма, ₽'
  ]
  tableBody: TPolicy[] = []

  paginationData: TPaginationData = {
    page: 5,
    pageSize: 30,
    pageCount: 1
  }
  paginationLoading = false

  get isSinglePage () {
    return this.paginationData.pageCount <= 1
  }

  get isDataEmpty () {
    return !this.tableBody?.length
  }

  created () {
    this.fetchPolicies()
  }

  async fetchPolicies () {
    try {
      const { data } = await this.$api.getKaskoPolicies()

      this.tableBody = data.items
      this.paginationData = data.meta
    } catch (error: unknown) {
      if (this.$api.isSafeError(error)) {
        this.$error.push(error)
      }
    }
  }

  async searchPolicies () {
    const isVin = this.search.match(/\D/g)
    const param = isVin ? { vin: this.search } : { cardId: Number(this.search) }

    try {
      const _param = this.search ? param : {}
      const { data } = await this.$api.getKaskoPolicies(_param)

      this.tableBody = data.items
      this.paginationData = data.meta
    } catch (error: unknown) {
      if (this.$api.isSafeError(error)) {
        this.$error.push(error)
      }
    }
  }

  onEnter (value: string) {
    this.search = value

    this.searchPolicies()
  }

  onExportClick (row: TRenderablePolicies) {}

  async onPageChanged (page: number) {
    this.paginationLoading = true

    try {
      const { data } = await this.$api.getKaskoPolicies({page})

      this.tableBody = data.items
      this.paginationData = data.meta
    } catch (error: unknown) {
      if (this.$api.isSafeError(error)) {
        this.$error.push(error)
      }
    }
  }
}
