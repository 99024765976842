
import { Vue, Component, Watch } from 'vue-property-decorator'

import Header from '@/components/layout/Header.vue'
import Tabs from '@/components/UI/Tabs.vue'

import { TDefaultTabs, TSerializeDefaultData } from '@/types/default'

@Component({
  components: {
    Header,
    Tabs
  }
})
export default class AppLayout extends Vue {
  tabOptions: TSerializeDefaultData[] = [
    { name: 'Машины', value: 'cars' },
    { name: 'Полисы', value: 'policies' }
  ]

  current: TDefaultTabs = 'cars'

  @Watch('current')
  onCurrentChanged(value: string) {
    this.$emit('update:current', value)
  }
}
