
import { Vue, Component, Prop, VModel } from 'vue-property-decorator'
import Input from '@/components/UI/Input.vue'

@Component({
  components: {
    Input
  }
})
export default class InputDate extends Vue {
  @Prop(String)
  readonly error: string

  @VModel({ type: String }) date!: string

  get classes () {
    return {
      'c-input_error': this.error
    }
  }
}
