
import { Vue, Component } from 'vue-property-decorator'
import _ from 'lodash'

import { TSpecialCar } from '@/types/api'
import { TRenderableTableDate } from '@/types/data_table'
import { TPaginationData } from '@/types/pagination'

import Input from '@/components/UI/Input.vue'
import Button from '@/components/UI/Button.vue'
import DataTable from '@/components/UI/DataTable'
import Pagination from '@/components/UI/Pagination.vue'
import AddCarModal from '@/components/modals/AddCar/AddCarModal.vue'
import PlanModal from '@/components/modals/Plan/PlanModal.vue'
import RemoveModal from '@/components/modals/Remove/RemoveModal.vue'

@Component({
  components: {
    Input,
    Button,
    DataTable,
    Pagination,
    AddCarModal,
    PlanModal,
    RemoveModal
  }
})
export default class Cars extends Vue {
  search: string = ''

  tableHead = [
    'VIN',
    'Карта',
    'Собственник',
    'Примечание',
    'Полис до',
    'КАСКО'
  ]

  modalAddCar: boolean = false
  modelPlan: boolean = false
  modelRemove: boolean = false

  tableBody: TSpecialCar[] = []

  limitCar: Nullable<TSpecialCar> = null

  selectedRows: TRenderableTableDate[] = []

  paginationData: TPaginationData = {
    page: 5,
    pageSize: 30,
    pageCount: 1
  }
  paginationLoading = false

  get isSinglePage () {
    return this.paginationData.pageCount <= 1
  }

  get isDataEmpty () {
    return !this.tableBody?.length
  }

  created () {
    this.$mitt.on('remove-cars', this.onRemoveClick)
    this.fetchCars()
    // this.$api.getCards()
  }

  beforeDestroy () {
    this.$mitt.off('remove-cars', this.onRemoveClick)
  }

  async fetchCars () {
    try {
      const { data } = await this.$api.getSpecialCars()

      this.tableBody = data.list
      this.paginationData = data.meta
    } catch (error: unknown) {
      if (this.$api.isSafeError(error)) {
        this.$error.push(error)
      }
    }
  }

  async searchCars () {
    const isVin = this.search.match(/\D/g)
    const param = isVin ? { vin: this.search } : { cardId: this.search }

    try {
      const _param = this.search ? param : {}
      const { data } = await this.$api.getSpecialCars(_param)

      this.tableBody = data.list
      this.paginationData = data.meta
    } catch (error: unknown) {
      if (this.$api.isSafeError(error)) {
        this.$error.push(error)
      }
    }
  }

  onKASKOPlanClick (row: TSpecialCar) {
    this.limitCar = row
    this.modelPlan = true
  }

  onRemoveClick (data: TRenderableTableDate | TRenderableTableDate[]) {
    this.selectedRows = Array.isArray(data) ? data : [data]
    this.modelRemove = true
  }

  onRemoveSuccess () {
    this.fetchCars()
    this.modelRemove = false
    this.selectedRows = []
  }

  onPlanSuccess () {
    this.fetchCars()
    this.modelPlan = false
    this.selectedRows = []
    this.limitCar = null
  }

  onEnter (value: string) {
    this.search = value

    this.searchCars()
  }

  async onPageChanged (page: number) {
    this.paginationLoading = true

    try {
      const { data } = await this.$api.getSpecialCars({page})

      this.tableBody = data.list
      this.paginationData = data.meta
    } catch (error: unknown) {
      if (this.$api.isSafeError(error)) {
        this.$error.push(error)
      }
    }
  }
}
