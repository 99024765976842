
import { Vue, Component, Watch, Prop, Emit } from 'vue-property-decorator'
import { parse, format } from 'date-fns'

import { TRenderableTableDate } from '@/types/data_table'

import Modal from '@/components/UI/Modal.vue'
import Button from '@/components/UI/Button.vue'
import DatePicker from '@/components/UI/DatePicker.vue'

export type TDateUpdate = {
  disabledAt: Nullable<string>
  id: string
}

@Component({
  components: {
    Modal,
    Button,
    DatePicker
  }
})
export default class PlanContent extends Vue {
  @Prop({ type: Object, default: () => null })
  data: TRenderableTableDate

  planDate: Date = new Date()
  oldDate: Date = new Date()
  dateUpdated: boolean = false

  error: string = ''

  get isSaveDisabled () {
    return !this.dateUpdated
  }

  @Emit('success')
  onSuccess (disabledAt: Nullable<string>): TDateUpdate {
    return {
      disabledAt,
      id: this.data.id
    }
  }

  @Watch('planDate')
  onPlanDateChanged () {
    if (!this.dateUpdated) {
      this.dateUpdated = true
    }
  }

  mounted () {
    const { disabledAt } = this.data.kasko

    this.oldDate = disabledAt ? parse(disabledAt, 'yyyy-MM-dd HH:mm:ss', new Date) : new Date()
    this.planDate = this.oldDate
  }

  async onSubmit (reset: boolean = false) {
    this.error = ''

    try {
      const disableAt = reset ? null : format(this.planDate, 'yyyy-MM-dd')
      const response = await this.$api.limitKaskoSpecialCars(this.data.id, { disableAt })

      this.onSuccess(response.data.disableAt)
    } catch (error: unknown) {
      if (this.$api.isSafeError(error)) {
        this.$error.push(error)
      }
    }
  }
}
