
import { Vue, Component, Emit } from 'vue-property-decorator'
import Button from '@/components/UI/Button.vue'

@Component({
  components: {
    Button
  }
})
export default class DataTableControls extends Vue {
  @Emit()
  onSelectAll () {}

  @Emit()
  onKaskoEnable () {}

  @Emit()
  onKaskoDisable () {}

  @Emit()
  onRemove () {}

  @Emit()
  onUncheckAll () {}
}
