
import { Vue, Component, Emit } from 'vue-property-decorator'
import { excelToJson } from '@/utils/excelToJson'

import Modal from '@/components/UI/Modal.vue'
import Button from '@/components/UI/Button.vue'
import InputFile from '@/components/UI/InputFile.vue'
import { TImportableJsonSpecialCar } from '@/types/json'

const validationKeys = [
  'VIN',
  'Год выпуска',
  'Название предприятия-владельца',
  'ИНН предприятия - владельца',
  'Коммерческое наименование',
  'Марка, модель ТС',
  'Масса Нетто ТС, кг',
  'Масса ТС, кг (В снаряженном состоянии)',
  'Номер топливной карты',
  'ПТС №',
  'СТС №'
]

@Component({
  components: {
    Modal,
    Button,
    InputFile
  }
})
export default class AddCarImportContent extends Vue {
  accept = 'application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel.sheet.macroEnabled.12'
  error: string = ''

  @Emit('ready')
  onReady (obj: TImportableJsonSpecialCar[]) {
    return obj
  }

  isImportedFileValid (arr: Record<string, any>[]) {
    if (!Array.isArray(arr)) {
      return false
    }

    if (!arr.length) {
      return false
    }

    /**
     * Проверяем, что объект содержит все доступные из таблицы поля
     */
    return arr.every(_item => {
      const keys = Object.keys(_item)

      return keys.every(_k => validationKeys.includes(_k))
    })
  }

  async onUpload (file: File) {
    this.error = ''

    try {
      const result = await excelToJson<TImportableJsonSpecialCar[]>(file)
      const valid = this.isImportedFileValid(result)

      if (!valid) {
        this.error = 'Импортированная таблица не валидна'

        return
      }

      this.onReady(result)
    } catch (error) {
      if (typeof error === 'string') {
        this.error = error
      }
    }
  }
}
