import { parse } from 'date-fns'
import isToday from 'date-fns/isToday'
import isBefore from 'date-fns/isBefore'
import isValid from 'date-fns/isValid'

// @ts-ignore
export const isValidAndNotFutureDate = (value: string) => {
  const date = parse(value, 'dd.MM.yyyy', new Date())
  const conclusion = isValid(date) && (isToday(date) || isBefore(date, new Date()))

  return isValid(date) && (isToday(date) || isBefore(date, new Date()))
}
