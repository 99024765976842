
import { Vue, Component, VModel, Emit, Prop } from 'vue-property-decorator'
import { TRenderableTableDate } from '@/types/data_table'

import Modal from '@/components/UI/Modal.vue'
import PlanContent, { TDateUpdate } from './PlanContent.vue'

@Component({
  components: {
    Modal,
    PlanContent
  }
})
export default class PlanModal extends Vue {
  @Prop({ type: Object, default: () => null })
  data: TRenderableTableDate

  @VModel({ type: Boolean })
  open: boolean

  @Emit('success')
  onSuccess (data: TDateUpdate) {
    return data
  }
}
