
import { Vue, Component, VModel } from 'vue-property-decorator'
import { TSelectIndicator } from '@/types/data_table'

@Component
export default class DataSelectIndicator extends Vue {
  @VModel({ type: String, default: '' })
  model: TSelectIndicator

  get classes () {
    return {
      'c-data-select-indicator_all': this.model === 'all',
      'c-data-select-indicator_partial': this.model === 'partial'
    }
  }

  onClick () {
    switch (this.model) {
      case '':
        this.model = 'all'
        break

      case 'all':
        this.model = ''
        break

      case 'partial':
        this.model = 'all'
        break
    }
  }
}
